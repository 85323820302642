import React from 'react'
import ReactMarkdown from 'react-markdown'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

const MissionStatement = ({ blade: { heading, body, logo, image } }) => {
    const _logo = getImage(logo?.gatsbyImageData)
    const _image = getImage(image?.gatsbyImageData)

    return (
        <div className="w-full pb-28 -mb-28 relative overflow-hidden">
            <span className="block absolute top-0 left-0 transform -translate-x-1/3 -translate-y-1/2 opacity-20 md:opacity-100">
                <span className="bub1 block w-56 h-56 border border-purple rounded-full z-0" />
            </span>

            <div className="block absolute bottom-32 left-full transform -translate-x-2/3 opacity-20 md:opacity-100">
                <span className="bub2 block w-56 h-56 border-4 border-purple rounded-full z-0" />
            </div>

            <div className="container mx-auto py-12 md:py-20 px-10 relative text-center z-10">
                <div className="flex flex-col justify-center items-center mt-4 md:mt-8 lg:mt-0">
                    {_logo && (
                        <GatsbyImage
                            className="max-w-[200px] md:max-w-[340px] mx-auto block mb-12 md:mb-20 drop-shadow-xl"
                            image={_logo}
                            objectFit="contain"
                            objectPosition="center"
                            alt={logo.alt ? logo.alt : 'Orbital Studio'}
                        />
                    )}

                    <h1 className="max-w-2xl mx-auto mb-8 md:mb-12 relative text-3xl md:text-4xl lg:text-5xl font-semibold text-purple z-20">
                        <ReactMarkdown>{heading}</ReactMarkdown>
                    </h1>

                    <div className="max-w-4xl mx-auto">
                        <ReactMarkdown>{body}</ReactMarkdown>
                    </div>

                    {_image && (
                        <div className="relative">
                            <span className="block absolute top-full left-1/4 transform -translate-x-1/2 -translate-y-2/3 opacity-20 md:opacity-100">
                                <span className="bub2 block w-56 h-56 border-2 border-purple rounded-full z-0" />
                            </span>

                            <GatsbyImage
                                className="w-full mx-auto block mt-12 md:mt-20 rounded-2xl"
                                image={_image}
                                objectFit="contain"
                                objectPosition="center"
                                alt={image.alt ? image.alt : 'Orbital Studio'}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default MissionStatement
