import React from 'react';

import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const GetDefaults = () => {
    const { favicon, site } = useStaticQuery(graphql`
        query getDefaults {
            favicon: datoCmsFaviconMetaTags {
                tags
            }
            site: datoCmsSite {
                noIndex
                globalSeo {
                    facebookPageUrl
                    siteName
                    titleSuffix
                    twitterAccount
                    fallbackSeo {
                        description
                        image {
                            url
                        }
                        title
                        twitterCard
                    }
                }
            }
        }
    `)

    return {
        ...favicon,
        ...site,
    }
}

const Meta = ({
    seo
}) => {
    const defaults = GetDefaults();

    const title = seo?.title ?? `${defaults?.globalSeo?.siteName}${defaults?.globalSeo?.titleSuffix}`;
    const description = seo?.description ?? defaults?.globalSeo?.fallBack?.description;
    const image = seo?.image?.url ?? defaults?.globalSeo?.fallbackSeo?.image?.url;
    const cardType = seo?.twitterCard ?? defaults?.globalSeo?.fallbackSeo?.twitterCard;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />

            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:card" content={cardType} />

            {defaults.noIndex && <meta name="robots" content="noindex" />}

            {defaults.tags.map(({ tagName, attributes }, i) => (tagName === 'link') && (
                <link
                    key={i}
                    href={attributes?.href}
                    rel={attributes?.rel}
                    sizes={attributes?.sizes}
                />
            ))}
        </Helmet>
    )
}

export default Meta
