import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'

import { useLightboxState } from '@context'

import Link from '@components/Link'
import LinkWithIcon from '@components/LinkWithIcon'

const StudioLife = ({
    blade: { heading, body, image, video, gridItems = [] },
}) => {
    const { setLightboxVideo, setLightboxOpen } = useLightboxState()

    const img = getImage(image.gatsbyImageData)

    const handleVideoClick = () => {
        setLightboxVideo(video)
        setLightboxOpen(true)
    }

    return (
        <div className="-mb-32 pb-32 relative overflow-hidden">
            <span className="block absolute bottom-0 left-0 transform -translate-x-1/2">
                <span className="bub3 block w-72 h-72 border-2 border-purple rounded-full z-20 opacity-20 md:opacity-100" />
            </span>

            <div className="container mx-auto py-12 md:py-20 px-10 relative text-center lg:text-left">
                <div className="grid grid-cols-full lg:grid-cols-14">
                    <div className="flex items-center lg:col-span-7 xl:col-span-7 row-start-2 lg:row-start-1 xl:col-start-2 mt-4 md:mt-8 lg:mt-0">
                        <div className="relative">
                            <GatsbyImage
                                image={img}
                                alt={image.alt ? image.alt : 'Orbital Studio'}
                                className="w-full relative h-full rounded-3xl z-20"
                                style={{ position: null }}
                                objectFit="cover"
                            />
                            {video && (
                                <Link
                                    clickHandler={() => handleVideoClick()}
                                    className="link-with-icon-hover flex items-center justify-center w-full h-full absolute top-0 left-0 z-30"
                                >
                                    <LinkWithIcon
                                        icon="play"
                                        color="mi-primary"
                                        clickHandler={() => true}
                                    />
                                </Link>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-col items-center lg:items-start justify-center lg:col-start-9 lg:col-span-6 xl:col-span-4 xl:col-start-10 w-full lg:w-auto">
                        {heading ? (
                            <h2 className="mb-4 md:mb-8 relative text-3xl md:text-4xl lg:text-5xl font-semibold text-purple z-20">
                                <ReactMarkdown>{heading}</ReactMarkdown>
                            </h2>
                        ) : null}

                        {body ? (
                            <div>
                                <ReactMarkdown>{body}</ReactMarkdown>
                            </div>
                        ) : null}
                    </div>
                </div>

                {gridItems?.length > 0 ? (
                    <div className="grid grid-cols-14 text-center">
                        <div className="col-span-full xl:col-span-12 col-start-1 xl:col-start-2 grid sm:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-4 mt-8 md:mt-16">
                            {gridItems?.map(({ image, heading, body }) => {
                                const img = getImage(image.gatsbyImageData)

                                return (
                                    <div>
                                        <div className="relative">
                                            <GatsbyImage
                                                image={img}
                                                alt={
                                                    image.alt
                                                        ? image.alt
                                                        : 'Orbital Studio'
                                                }
                                                className="w-full mb-2 sm:mb-4 md:mb-8 relative h-full rounded-3xl z-30"
                                                style={{ position: null }}
                                                objectFit="cover"
                                            />
                                        </div>
                                        <div className="px-4">
                                            <h3 className="mb-4 font-semibold text-xl text-purple">
                                                <ReactMarkdown>
                                                    {heading}
                                                </ReactMarkdown>
                                            </h3>
                                            <span className="text-sm">
                                                {body}
                                            </span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default StudioLife
