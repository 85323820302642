import React from 'react'
import cx from 'classnames'

import { trimString } from '@config/helpers'

import Icon from '@components/Icon'

import './styles.scss'

const getFirstParagraph = (string) => {
    const match = string.match(/<(\w+)>(.*?)<\/\1>/)
    return match?.[2] || ''
}

const CareerCard = ({
    title,
    shortcode,
    description,
    department,
    keywords,
    hide = false,
    className,
    application_url,
}) => {
    return (
        <div
            className={cx(
                'career-card group transform origin-center hover:scale-105 hover:z-20 transition duration-200',
                className,
                { hidden: hide }
            )}
        >
            <a
                href={`https://ndreams.com/careers/${shortcode}`}
                rel="noreferrer noopenner"
                className="flex flex-col min-h-full overflow-hidden"
            >
                <div className="flex-1 p-6 career-card__inner">
                    <div className="mb-8 flex items-start justify-between">
                        <h3 className="text-2xl text-black font-bold">
                            {title}
                        </h3>
                        <Icon
                            name={keywords?.[0]?.toLowerCase()}
                            className="career-card__icon ml-4 text-primary"
                            alt={department}
                        />
                    </div>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: trimString({
                                str: getFirstParagraph(description),
                            }),
                        }}
                    />
                </div>
                <div className="career-card__link p-6 relative border-t border-dashed border-aldo overflow-hidden">
                    <span className="relative text-purple text-lg font-bold uppercase z-10">
                        More Info
                    </span>
                </div>
            </a>
        </div>
    )
}

export default CareerCard
