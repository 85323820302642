import React from 'react';

import { LightboxProvider, useLightboxState } from './LightboxProvider';
import { MenuProvider, useMenuState } from './MenuProvider';

const ContextProvider = ({ children }) => (
    <MenuProvider>
        <LightboxProvider>
            {children}
        </LightboxProvider>
    </MenuProvider>
)

export {
    ContextProvider,
    useLightboxState,
    useMenuState,
}