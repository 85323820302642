import React from 'react'
import cx from 'classnames'

import Icon from '@components/Icon';

const Checkbox = ({
    name,
    label,
    checked = false,
    changeHandler,
    className,
}) => {
    const handleChange = (e) => {
        e.preventDefault()
        typeof changeHandler === 'function' && changeHandler()
    }

    return (
        <div>
            <label htmlFor={name} className={cx('inline-flex items-center select-none cursor-pointer', className)} onClick={(e) => handleChange(e)}>
                <input
                    {...{
                        type: 'checkbox',
                        name,
                        checked,
                        className: 'hidden',
                        onChange: () => true,
                    }}
                />
                <span className={`flex items-center justify-center h-6 w-6 mr-4 border-2 border-black border-solid bg-white ${checked ? 'text-black' : 'text-white'}`}>
                    <Icon name="check" />
                </span>
                {label && <span>{label}</span>}
            </label>
        </div>
    )
}

export default Checkbox
