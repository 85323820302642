import React, { createContext, useState, useContext } from 'react';

const MenuContext = createContext();

const MenuProvider = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <MenuContext.Provider value={{ menuOpen, setMenuOpen }}>
            {children}
        </MenuContext.Provider>
    )
}

const useMenuState = () => {
    const context = useContext(MenuContext);

    if (context === undefined) throw new Error('useMenuState must be used within a MenuProvider')

    return context
}

export { MenuProvider, useMenuState }