import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'

import { getModelSlug } from '@config/helpers'
import { useLightboxState } from '@context'

import Link from '@components/Link'
import LinkWithIcon from '@components/LinkWithIcon'

import './styles.scss'

const ImageWithText = ({
    blade: {
        backgroundColour,
        image,
        imagePosition,
        imageText,
        video,
        heading,
        body,
        showLink,
        scrollToElement,
        elementId,
        link,
        linkText,
        playButtonColour,
        verticalLabel,
    },
}) => {
    const { setLightboxVideo, setLightBoxOpen } = useLightboxState()

    const img = getImage(image.gatsbyImageData)

    const handleVideoClick = () => {
        setLightboxVideo(video)
        setLightBoxOpen(true)
    }

    const handleScrollTop = (e) => {
        e.preventDefault()

        const elmt = document.getElementById(elementId)
        if (typeof elmt?.scrollIntoView === 'function') {
            elmt.scrollIntoView({ behavior: 'smooth' })
        }
    }

    return (
        <div
            className={`image-with-text relative ${
                backgroundColour === 'Grey' ? 'bg-bare' : 'bg-white'
            }`}
        >
            <div className="container mx-auto px-10 relative">
                <div className="md:grid md:grid-cols-14 md:grid-flow-col">
                    {verticalLabel && (
                        <div
                            className={`py-12 md:py-16 xl:py-28 hidden ${
                                imagePosition === 'Right'
                                    ? 'xl:block'
                                    : 'xl:flex items-start justify-end'
                            }`}
                        >
                            <div className="vert-label">{verticalLabel}</div>
                        </div>
                    )}
                    <div
                        className={`flex flex-col items-start justify-center py-12 md:py-16 xl:py-28 col-span-14 md:col-span-6 xl:col-span-6 ${
                            imagePosition === 'Left'
                                ? 'md:col-start-9 xl:col-start-9'
                                : 'xl:col-start-2'
                        } 2xl:pr-4`}
                    >
                        {heading && (
                            <h2 className="line-styled-heading mb-8 md:mb-12 relative text-3xl sm:text-3xl lg:text-4xl font-extrabold uppercase">
                                <ReactMarkdown children={heading} />
                            </h2>
                        )}
                        {body && (
                            <ReactMarkdown
                                children={body}
                                className="wysiwyg"
                            />
                        )}
                        {showLink &&
                            (link || (scrollToElement && elementId)) && (
                                <LinkWithIcon
                                    clickHandler={(e) =>
                                        scrollToElement
                                            ? handleScrollTop(e)
                                            : true
                                    }
                                    to={
                                        link?.slug && link?.model?.name
                                            ? `${getModelSlug(
                                                  link?.model?.name
                                              )}/${link?.slug}`
                                            : null
                                    }
                                    text={linkText}
                                    icon="arrowRight"
                                    className="mt-8 md:mt-12"
                                />
                            )}
                    </div>
                    <div
                        className={`mb-12 md:mb-0 col-span-6 ${
                            imagePosition === 'Right'
                                ? 'col-start-9'
                                : 'col-start-1'
                        }`}
                    >
                        <div className="relative h-full">
                            <GatsbyImage
                                image={img}
                                alt={image.alt ? image.alt : 'Orbital Studio'}
                                className="w-full relative h-full z-30"
                                style={{ position: null }}
                                objectFit="cover"
                            />
                            {video && (
                                <Link
                                    clickHandler={() => handleVideoClick()}
                                    className="link-with-icon-hover flex items-center justify-center w-full h-full absolute top-0 left-0"
                                >
                                    {imageText && (
                                        <h3 className="md:hidden lg:block video-with-text__image-text w-full p-8 absolute bottom-0 right-0 text-4xl sm:text-5xl md:text-4xl 2xl:text-5xl text-right text-black font-extrabold uppercase">
                                            <span className="line-styled-heading inline-block max-w-xs xl:max-w-sm">
                                                {imageText}
                                            </span>
                                        </h3>
                                    )}
                                    <LinkWithIcon
                                        icon="play"
                                        color={
                                            playButtonColour === 'White'
                                                ? 'mi-light'
                                                : 'mi-dark'
                                        }
                                        clickHandler={() => true}
                                    />
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageWithText
