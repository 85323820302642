import React, { createElement } from 'react'

import ImageWithText from '@components/ImageWithText'
import ImageHero from '@components/ImageHero'
import FeaturedGame from '@components/FeaturedGame'
import GamesCarousel from '@components/GamesCarousel'
import LatestNews from '@components/LatestNews'
import Projects from '@components/Projects'
import CareersArchive from '@components/CareersArchive'
import StudioLife from '@components/StudioLife'
import MissionStatement from '@components/MissionStatement'
import ImagePill from '@components/ImagePill'
import MeetTheTeam from '@components/MeetTheTeam'

const Blades = ({ blades = [], game }) => {
    const Components = {
        DatoCmsImageHero: ImageHero,
        DatoCmsImageWithText: ImageWithText,
        DatoCmsFeaturedGame: FeaturedGame,
        DatoCmsGamesCarousel: GamesCarousel,
        DatoCmsLatestNews: LatestNews,
        DatoCmsNdreamsStudioProject: Projects,
        DatoCmsCareersArchive: CareersArchive,
        DatoCmsStudioLifeOrbital: StudioLife,
        DatoCmsMissionStatementOrbital: MissionStatement,
        DatoCmsImagePillOrbital: ImagePill,
        DatoCmsMeetTheTeamOrbital: MeetTheTeam,
    }

    return blades.map((blade, index) => {
        if (Components[blade.__typename]) {
            return createElement(Components[blade.__typename], {
                key: index,
                blade: blade,
            })
        }
    })
}

export default Blades
