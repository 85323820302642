import React from 'react'
import cx from 'classnames'

import Link from '@components/Link'
import Icon from '@components/Icon'

import './styles.scss'

const LinkWithIcon = ({
    color = 'mi-dark',
    to,
    external = true,
    newTab = true,
    text,
    icon,
    clickHandler,
    className,
}) =>
    to ? (
        !external ? (
            <Link
                {...{
                    to,
                    external,
                    clickHandler,
                    className: cx(
                        'link-with-icon inline-flex items-center text-lg font-bold uppercase cursor-pointer select-none',
                        className,
                        color
                    ),
                }}
            >
                {text && <span>{text}</span>}
                {icon && (
                    <div
                        className={cx(
                            'link-with-icon__circle inline-flex items-center justify-center relative select-none',
                            { 'ml-4 md:ml-8': text }
                        )}
                    >
                        <Icon
                            name={icon}
                            className={cx('link-with-icon__icon', icon)}
                        />
                    </div>
                )}
            </Link>
        ) : (
            <a
                href={to}
                rel="noreferrer noopenner"
                target={newTab ? '_blank' : null}
                {...{
                    onClick: (e) => clickHandler(e),
                    className: cx(
                        'link-with-icon inline-flex items-center text-lg font-bold uppercase cursor-pointer select-none',
                        className,
                        color
                    ),
                }}
            >
                {text && <span>{text}</span>}
                {icon && (
                    <div
                        className={cx(
                            'link-with-icon__circle inline-flex items-center justify-center relative',
                            { 'ml-4 md:ml-8': text }
                        )}
                    >
                        <Icon
                            name={icon}
                            className={cx('link-with-icon__icon', icon)}
                        />
                    </div>
                )}
            </a>
        )
    ) : (
        <span
            {...{
                onClick: (e) => clickHandler(e),
                className: cx(
                    'link-with-icon inline-flex items-center text-lg font-bold uppercase cursor-pointer select-none',
                    className,
                    color
                ),
            }}
        >
            {text && <span>{text}</span>}
            {icon && (
                <div
                    className={cx(
                        'link-with-icon__circle inline-flex items-center justify-center relative',
                        { 'ml-4 md:ml-8': text }
                    )}
                >
                    <Icon
                        name={icon}
                        className={cx('link-with-icon__icon', icon)}
                    />
                </div>
            )}
        </span>
    )

export default LinkWithIcon
