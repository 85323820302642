import React from 'react'
import cx from 'classnames'

import './styles.scss'

const Toggle = ({
    active = false,
    toggleActive,
}) => (
    <div onClick={typeof toggleActive === 'function' && toggleActive} className={cx('toggle relative cursor-pointer', { active })} />
)

export default Toggle
