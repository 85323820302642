import React, { Fragment } from 'react'

import { ContextProvider } from '@context'
import Header from '@components/Header'
import Footer from '@components/Footer'
import Lightbox from '@components/Lightbox'
import CookieBanner from '@components/CookieBanner'

const Root = ({ headerColour, children, game }) => (
    <ContextProvider>
        <Fragment>
            <Header color={headerColour} />
            <div className="pt-[68px] lg:pt-[76px]">
                {children}
            </div>
            <Lightbox />
            <CookieBanner />
            <Footer {...{ game }} />
        </Fragment>
    </ContextProvider>
)

export default Root
