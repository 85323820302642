import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';

import { mapCmsColour } from '@config/helpers';
import { useLightboxState } from '@context';

import LinkWithIcon from '@components/LinkWithIcon';

import './styles.scss';

const FeaturedGame = ({ blade: { game: { title, slug, tagline, trailer, logo, featuredImage }, labelColour, textColour } }) => {

    const { setLightboxVideo, setLightboxOpen } = useLightboxState();

    const bgImg = getImage(featuredImage.gatsbyImageData);
    const background = convertToBgImage(bgImg);

    const logoImg = getImage(logo.gatsbyImageData);

    const handleVideoClick = () => {
        setLightboxVideo(trailer);
        setLightboxOpen(true);
    }

    return (
        <div className="featured-game relative pt-28 pb-8 md:py-32 lg:py-48 overflow-hidden">
            <BackgroundImage tag="Section" {...background} style={{ position: null }} className="featured-game__background md:w-full md:h-full pt-full md:pt-0 mb-8 md:m-0 relative md:absolute md:top-0 md:left-0 bg-black bg-cover bg-center md:bg-right bg-no-repeat z-10">
                <GatsbyImage image={logoImg} className="md:hidden w-4/5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" style={{ position: null }} />
            </BackgroundImage>
            <div className="container mx-auto relative z-20">
                <div className="grid grid-cols-1 md:grid-cols-14 px-10">
                    <div className="col-span-12 md:col-span-8 lg:col-span-7 xl:col-span-5 2xl:col-span-4 xl:col-start-2 2xl:col-start-2">
                        <span
                            className={`block mb-2 text-xl sm:text-2xl font-bold text-${mapCmsColour(
                                labelColour
                            )} uppercase`}
                        >
                            Featured
                        </span>
                        <h2
                            className={`featured-game__heading text-black color-${mapCmsColour(
                                textColour
                            )} text-4xl sm:text-5xl md:text-6xl font-extrabold uppercase`}
                        >
                            {title}
                        </h2>
                        {tagline && (
                            <p
                                className={`featured-game__tagline py-8 md:py-10 text-black font-thin text-2xl color-${mapCmsColour(
                                    textColour
                                )} border-b border-dashed border-aldo md:border-white`}
                            >
                                {tagline}
                            </p>
                        )}

                        <div className="flex items-center justify-between pt-6">
                            <LinkWithIcon
                                color={`mi-dark md-${mapCmsColour(textColour)}`}
                                to={`/games/${slug}`}
                                text="See more"
                                icon="arrowRight"
                            />
                            {trailer?.providerUid && (
                                <LinkWithIcon
                                    color={`mi-dark md-${mapCmsColour(
                                        textColour
                                    )}`}
                                    clickHandler={() => handleVideoClick()}
                                    text="Play trailer"
                                    icon="play"
                                    className="hidden sm:inline-flex"
                                />
                            )}
                        </div>
                    </div>
                    <div className="hidden xl:block col-span-7 col-start-8 text-center">
                        <GatsbyImage image={logoImg} alt="logo" />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FeaturedGame;